import React from "react"
import Player from "../../../blocks/en/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "osipov" }) {
    ...PlayerInfoEn
  }
  stats: allStatsYaml {
    nodes {
      key
      value_en
      average
    }
  }
}`

export default class Osipov extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>A tenacious left back, finishes every episode, has good speed and skills of tight marking.</p>
          <p>Left-footed, can also play as a left midfielder. Coordinated, able to quickly change the direction of his movement.</p>
          <p>If necessary, he can support the game in the midfield (as a left inside), quickly accepts the ball, does not hesitate with decisions.</p>
          <p>A tactically competent, disciplined player, can play in three positions. Confident and quickly becomes involved in the game when substituted in.</p>
        </div>
      </div>
    )
  }
}